import React, { useState, useEffect } from 'react';
import { ChakraProvider, Flex } from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import LoginPage from './pages/LoginPage';
import SignUpPage from './pages/SignUpPage';
import ResetPassword from './pages/ResetPassword';
import Account from './pages/Account';
import Payment from './pages/Payment';
import Link from './components/Link/Link';
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import theme from "./theme"
import Contact from "./pages/Contact";
import Blog from "./pages/Blog";
import Blog1 from "./pages/Blog1";
import Blog2 from "./pages/Blog2";
import Blog3 from "./pages/Blog3";
import Blog4 from "./pages/Blog4";
import Blog5 from "./pages/Blog5";
import GetStartedInTech from "./pages/GetStartedInTech";
import SoftwareEngineering from "./pages/SoftwareEngineering";
import WebDevelopment from "./pages/WebDevelopment";
import TechnicalProjectManagement from "./pages/TechnicalProjectManagement";
import GetStartedInTechResources from "./pages/GetStartedInTechResources";
import Recommendations from "./pages/Recommendations";
import RecommendedBooks from "./pages/RecommendedBooks";
import RecommendedOnlineCourses from "./pages/RecommendedOnlineCourses";

function App() {
  const [clientSecret, setClientSecret] = useState("");
  
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Nav />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/login" element={<LoginPage />} />
          <Route exact path="/signup" element={<SignUpPage />} />
          <Route exact path="/payment" element={<Payment />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/blog" element={<Blog />} />
          <Route exact path="/blog/17916eab-0c88-4457-abfb-65d43983d276" element={<Blog1 />} />
          <Route exact path="/blog/b8186041-a73f-411b-a202-463d1cf422e2" element={<Blog2 />} />
          <Route exact path="/blog/88ed2722-7182-4c78-abaf-d489309af7b5" element={<Blog3 />} />
          <Route exact path="/blog/d0975504-ca28-4bda-ad5a-11de546c89af" element={<Blog4 />} />
          <Route exact path="/blog/fe23da90-3094-4be3-9c5e-1d770585d581" element={<Blog5 />} />
          <Route exact path="/web-development" element={<WebDevelopment />} />
          <Route exact path="/software-engineering" element={<SoftwareEngineering />} />
          <Route exact path="/technical-project-management" element={<TechnicalProjectManagement />} />
          <Route exact path="/get-started-in-tech" element={<GetStartedInTech />} />
          <Route exact path="/get-started-in-tech-resources" element={<GetStartedInTechResources />} />
          <Route exact path="/recommendations" element={<Recommendations />} />
          <Route exact path="/recommended-books" element={<RecommendedBooks />} />
          <Route exact path="/recommended-online-courses" element={<RecommendedOnlineCourses />} />
        </Routes>
        <Footer />
      </Router>
    </ChakraProvider>
  );
}

export default App;
