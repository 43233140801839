import React from 'react';
import {
  Box,
  Heading,
  Link,
  Image,
  Text,
  Divider,
  HStack,
  Tag,
  Wrap,
  WrapItem,
  SpaceProps,
  useColorModeValue,
  Container,
  VStack,
} from '@chakra-ui/react';

interface IBlogTags {
  tags: Array<string>;
  marginTop?: SpaceProps['marginTop'];
}

const BlogTags: React.FC<IBlogTags> = (props) => {
  return (
    <HStack spacing={2} marginTop={props.marginTop}>
      {props.tags.map((tag) => {
        return (
          <Tag size={'md'} variant="solid" colorScheme="orange" key={tag}>
            {tag}
          </Tag>
        );
      })}
    </HStack>
  );
};

interface BlogAuthorProps {
  date: Date;
  name: string;
}

export const BlogAuthor: React.FC<BlogAuthorProps> = (props) => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Image
        borderRadius="full"
        boxSize="40px"
        src="https://100k-faces.glitch.me/random-image"
        alt={`Avatar of ${props.name}`}
      />
      <Text fontWeight="medium">{props.name}</Text>
      <Text>—</Text>
      <Text>{props.date.toLocaleDateString()}</Text>
    </HStack>
  );
};

const ArticleList = () => {
  return (
    <Container maxW={'7xl'} p="12">
      <VStack spacing="2" alignItems="flex-start">
        <Heading as="h2">Top resume suggestions for aspiring developers</Heading>
        <Box mt={8}>
  <Text mb={4}>
  As an aspiring developer, your resume is an important tool that showcases your skills and experience to potential employers. A strong resume can help you stand out in a crowded job market and increase your chances of getting hired. Here are some top resume suggestions for aspiring developers:  </Text>
  <Heading as="h2" size="lg" mb={2}>
    1. Keep it simple
  </Heading>
  <Text mb={4}>
  A cluttered, poorly formatted resume can be a turn-off to hiring managers. Instead, aim for a clean and simple design that is easy to read. Use clear headings and bullet points to organize your information and highlight your most relevant skills and experience. Avoid using decorative fonts or adding unnecessary graphics.  </Text>
  <Heading as="h2" size="lg" mb={2}>
    2. Focus on your achievements
  </Heading>
  <Text mb={4}>
  Rather than just listing your responsibilities and duties, highlight your accomplishments and the impact you had in your previous roles. For example, if you developed a new feature for a project that increased user engagement, be sure to mention this and quantify the impact. This will show potential employers that you are a problem-solver who can deliver results.  </Text>
  <Heading as="h2" size="lg" mb={2}>
    3. Use keywords
  </Heading>
  <Text mb={4}>
  Many companies use applicant tracking systems (ATS) to scan resumes for keywords related to the job posting. To increase your chances of being noticed, make sure to include relevant keywords in your resume, such as specific programming languages or tools you have experience with. Don't stuff your resume with unnecessary keywords, but do include the ones that are most relevant to the job you're applying for.  </Text>
  <Heading as="h2" size="lg" mb={2}>
    4. Tailor your resume to the job
  </Heading>
  <Text mb={4}>
  Don't send the same generic resume to every job you apply for. Instead, customize your resume to highlight the skills and experience that are most relevant to the specific job you're applying for. This will show the hiring manager that you have done your research and are a strong fit for the role.  </Text>
  <Heading as="h2" size="lg" mb={2}>
    5. Keep it current
  </Heading>
  <Text mb={4}>
  Make sure to update your resume regularly to reflect your most recent experience and skills. It's also a good idea to review and update your resume before applying for a new job, to ensure that all the information is accurate and up-to-date.  </Text>
  <Heading as="h2" size="lg" mb={2}>
    6. Use action verbs
  </Heading>
  <Text mb={4}>
  Rather than using passive language, use action verbs to describe your responsibilities and achievements. This will make your resume more dynamic and show potential employers that you are proactive and have a track record of getting things done.  </Text>
  <Heading as='h2' size='lg' mb={2}>
  7. Include a summary statement
  </Heading>
  <Text mb={4}>
  A summary statement is a short paragraph at the top of your resume that summarizes your skills and experience. This is a great opportunity to grab the hiring manager's attention and highlight your most relevant qualifications. Keep it concise and focus on the key points that make you a strong candidate for the role.  </Text>

  <Heading as='h2' size='lg' mb={2}>
  8. Proofread
  </Heading>
  <Text mb={4}>
  A resume with spelling and grammar errors can be a major red flag to hiring managers. Be sure to carefully proofread your resume before sending it out to ensure that it is error-free. It can also be helpful to have someone else review your resume for any mistakes you may have missed.  </Text>
  <Text>
  A well-crafted resume is essential for aspiring developers looking to stand out in the job market. By following these suggestions, you can create a resume that showcases your skills and experience in the best possible light and helps you land your dream job.  </Text>
  </Box>
      </VStack>
    </Container>
  );
};

export default ArticleList;