import React from 'react';
import {
  Box,
  Heading,
  Link,
  Image,
  Text,
  Divider,
  HStack,
  Tag,
  Wrap,
  WrapItem,
  SpaceProps,
  useColorModeValue,
  Container,
  VStack,
} from '@chakra-ui/react';
import GetStartedTech from '../components/GetStartedTech';

interface IBlogTags {
  tags: Array<string>;
  marginTop?: SpaceProps['marginTop'];
}

const BlogTags: React.FC<IBlogTags> = props => {
  return (
    <HStack spacing={2} marginTop={props.marginTop}>
      {props.tags.map(tag => {
        return (
          <Tag size={'md'} variant="solid" colorScheme="orange" key={tag}>
            {tag}
          </Tag>
        );
      })}
    </HStack>
  );
};

interface BlogAuthorProps {
  date: Date;
  name: string;
}

export const BlogAuthor: React.FC<BlogAuthorProps> = props => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Image
        borderRadius="full"
        boxSize="40px"
        src="https://100k-faces.glitch.me/random-image"
        alt={`Avatar of ${props.name}`}
      />
      <Text fontWeight="medium">{props.name}</Text>
      <Text>—</Text>
      <Text>{props.date.toLocaleDateString()}</Text>
    </HStack>
  );
};

const ArticleList = () => {
  return (
    <Container maxW={'7xl'} p="12">
      <GetStartedTech />

      <br />

      <Text fontWeight="bold" color="yellow.700">
        FYI: I'm a proud affiliate for some of the resources discussed below. If
        you make a purchase through my links, I'll get a small commision. Thank
        you
      </Text>

      <VStack marginTop="10" spacing="2" alignItems="flex-start">
        <Heading as="h2">Online courses</Heading>
        <Text>
          There are many online tools and resources available to help you get
          started in the tech industry. Here are a few that might be helpful:
        </Text>

        <Text as="p" fontWeight="bold">
          1. Codecademy:
        </Text>
        <Text as="p">
          This is a popular platform for learning how to code. It offers
          interactive courses in a variety of programming languages, including
          Python, Java, C++, and more.
        </Text>

        <Text as="p" fontWeight="bold">
          2. Coursera:
        </Text>
        <Text as="p">
          This online learning platform offers a wide range of courses and
          certifications in tech-related topics, including computer science,
          data science, and software engineering.
        </Text>

        <Text as="p" fontWeight="bold">
          3. edX:
        </Text>
        <Text as="p">
          Similar to Coursera, edX is an online learning platform that offers
          courses and certifications in tech-related subjects. It also has a
          focus on offering courses from top universities and institutions
          around the world.
        </Text>

        <Text as="p" fontWeight="bold">
          4. Khan Academy:
        </Text>
        <Text as="p">
          This non-profit organization offers free online courses and resources
          in a variety of subjects, including computer science.
        </Text>

        <Text as="p" fontWeight="bold">
          5. GitHub:
        </Text>
        <Text as="p">
          This is a platform for developers to host and review code, manage
          projects, and build software. It's a great place to find open-source
          projects to contribute to and to showcase your own projects.
        </Text>

        <Text as="p" fontWeight="bold">
          6. Stack Overflow:
        </Text>
        <Text as="p">
          This is a popular question and answer site for programmers. It's a
          great place to ask for help or advice on technical issues you're
          encountering.
        </Text>

        <Text as="p">
          These are just a few examples, and there are many other online tools
          and resources available as well. It's a good idea to do some research
          and see what works best for you.
        </Text>
      </VStack>

      <VStack marginTop="10" spacing="2" alignItems="flex-start">
        <Heading as="h2">Resume suggestions</Heading>
        <Text>
          When it comes to creating a resume for the tech industry, there are a
          few key things you should keep in mind. Here are a few tips to help
          you stand out:
        </Text>

        <Text as="p" fontWeight="bold">
          1. Highlight your technical skills:
        </Text>
        <Text as="p">
          As you might expect, tech companies are looking for candidates with
          strong technical skills. Make sure to highlight any relevant
          programming languages, frameworks, or tools you have experience with.
        </Text>

        <Text as="p" fontWeight="bold">
          2. Include any relevant coursework or certifications:
        </Text>
        <Text as="p">
          If you've taken any tech-related courses or have any certifications,
          make sure to include them on your resume. These can help demonstrate
          your commitment to learning and your knowledge of the field.
        </Text>

        <Text as="p" fontWeight="bold">
          3. Show off your projects:
        </Text>
        <Text as="p">
          If you have any personal projects or contributions to open-source
          projects, make sure to include them on your resume. These can be great
          examples of your technical skills and problem-solving abilities.
        </Text>

        <Text as="p" fontWeight="bold">
          4. Don't be afraid to include non-technical experience:
        </Text>
        <Text as="p">
          Even if you don't have a lot of technical experience, don't be afraid
          to include other types of experience on your resume. Any experience
          that demonstrates your ability to work in a team, solve problems, or
          communicate effectively can be valuable in the tech industry.
          <Text as="p" fontWeight="bold">
            5. Keep it concise and to the point:
          </Text>
          <Text as="p">
            Tech companies receive a lot of resumes, so it's important to make
            sure yours stands out. Keep it concise and focus on the most
            relevant information. Use bullet points and avoid long paragraphs to
            make it easier for the reader to quickly scan and understand your
            background.
          </Text>
        </Text>
      </VStack>

      <VStack marginTop="10" spacing="2" alignItems="flex-start">
        <Heading as="h2">Books</Heading>

        <Text fontWeight="bold">
          There are many books available that can help you get started in the
          tech industry. Here are a few suggestions:
        </Text>

        <br />

        <Link color="blue.500" href="https://amzn.to/3X4iuAf">
          <Text as="p" fontWeight="bold">
            "Cracking the Coding Interview" by Gayle Laakmann McDowell:
          </Text>
        </Link>
        <Text as="p">
          This book is a classic for anyone looking to break into the tech
          industry. It includes practice questions and tips for acing the
          technical interview, which is a key step in getting hired at many tech
          companies.
        </Text>

        <br />

        <Link color="blue.500" href="https://amzn.to/3Zm9ZCy">
          <Text as="p" fontWeight="bold">
            "The Lean Startup" by Eric Ries
          </Text>
        </Link>
        <Text as="p">
          This book is a must-read for anyone interested in entrepreneurship or
          innovation. It provides a framework for building and launching
          successful tech products and companies.
        </Text>

        <br />

        <Link color="blue.500" href="https://amzn.to/3WWX9cB">
          <Text as="p" fontWeight="bold">
            "Hooked: How to Build Habit-Forming Products" by Nir Eyal
          </Text>
        </Link>
        <Text as="p">
          This book is a great resource for anyone interested in building
          successful tech products that users will love. It covers the
          psychology behind habit-forming products and provides practical advice
          for building them.
        </Text>

        <br />

        <Link color="blue.500" href="https://amzn.to/3ZmuMWN">
          <Text as="p" fontWeight="bold">
            "The Innovator's Dilemma" by Clayton M. Christensen
          </Text>
        </Link>
        <Text as="p">
          This book is a classic in the tech industry and is a must-read for
          anyone interested in innovation. It discusses the challenges that
          companies face when trying to innovate and provides strategies for
          overcoming them.
        </Text>

        <br />

        <Link color="blue.500" href="https://amzn.to/3jSAb7v">
          <Text as="p" fontWeight="bold">
            "The Pragmatic Programmer" by Andrew Hunt and David Thomas
          </Text>
        </Link>
        <Text as="p">
          This book is a great resource for developers of all levels. It covers
          a wide range of topics, including coding practices, debugging, and
          project management, and is a great resource for anyone looking to
          improve their skills as a developer.
        </Text>

        <br />

        <Text as="p">
          There are many other great books out there as well, so it's a good
          idea to do some research and find the ones that are most relevant to
          your interests and goals.
        </Text>
      </VStack>
    </Container>
  );
};

export default ArticleList;
