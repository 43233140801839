import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { auth, sendPasswordReset } from '../firebase';
import Link from '../components/Link/Link';
import {
  Button,
  FormControl,
  FormLabel,
  Box,
  Flex,
  Heading,
  Input,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

function ResetPassword() {
  const [email, setEmail] = useState('');
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) return;
    if (user) navigate('/');
  }, [user, loading, navigate]);
  return (
    <Flex 
    minH={'100vh'}
    align={'center'}
    justify={'center'}
    bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack
        spacing={4}
        w={'full'}
        maxW={'md'}
        bg={useColorModeValue('white', 'gray.700')}
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        my={12}>
          <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
          Forgot your password?
        </Heading>
        <Text
          fontSize={{ base: 'sm', sm: 'md' }}
          color={useColorModeValue('gray.800', 'gray.400')}>
          You&apos;ll get an email with a reset link
        </Text>
      <form
        onSubmit={e => {
          e.preventDefault();
          sendPasswordReset(email);
        }}
      >
        <FormControl>
          <Input
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="your-email@example.com"
            autoComplete="email"
          />
        </FormControl>
        <Button bg="#4285f4" color="white" w="100%" my="2" type="submit">
          Send password reset email
        </Button>
      </form>
      </Stack>
    </Flex>
  );
}
export default ResetPassword;
