import React from 'react';
import {
  Box,
  Heading,
  Image,
  Text,
  Divider,
  HStack,
  Tag,
  Wrap,
  WrapItem,
  SpaceProps,
  useColorModeValue,
  Container,
  VStack,
  List,
  ListItem,
  Link,
} from '@chakra-ui/react';

import GetStartedWebDev from '../components/GetStartedWebDev';

interface IBlogTags {
  tags: Array<string>;
  marginTop?: SpaceProps['marginTop'];
}

const BlogTags: React.FC<IBlogTags> = props => {
  return (
    <HStack spacing={2} marginTop={props.marginTop}>
      {props.tags.map(tag => {
        return (
          <Tag size={'md'} variant="solid" colorScheme="orange" key={tag}>
            {tag}
          </Tag>
        );
      })}
    </HStack>
  );
};

interface BlogAuthorProps {
  date: Date;
  name: string;
}

export const BlogAuthor: React.FC<BlogAuthorProps> = props => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Image
        borderRadius="full"
        boxSize="40px"
        src="https://100k-faces.glitch.me/random-image"
        alt={`Avatar of ${props.name}`}
      />
      <Text fontWeight="medium">{props.name}</Text>
      <Text>—</Text>
      <Text>{props.date.toLocaleDateString()}</Text>
    </HStack>
  );
};

const ArticleList = () => {
  return (
    <Container maxW={'7xl'} p="12">
      <VStack spacing="2" alignItems="flex-start">
        <GetStartedWebDev />
        <br />

        <Text fontWeight="bold" color="yellow.700">
          FYI: I'm a proud affiliate for some of the resources discussed below.
          If you make a purchase through my links, I'll get a small commision.
          Thank you
        </Text>
      </VStack>

      <VStack marginTop="10" spacing="2" alignItems="flex-start">
        <Heading as="h2">Online courses</Heading>
        <Text>
          There are many online tools and resources available to help you get
          started in the tech industry. Here are a few that might be helpful:
        </Text>

        <Text as="p" fontWeight="bold">
          1. Codecademy:
        </Text>
        <Text as="p">
          This is a popular platform for learning how to code. It offers
          interactive courses in a variety of programming languages, including
          Python, Java, C++, and more.
        </Text>

        <Text as="p" fontWeight="bold">
          2. Coursera:
        </Text>
        <Text as="p">
          This online learning platform offers a wide range of courses and
          certifications in tech-related topics, including computer science,
          data science, and software engineering.
        </Text>

        <Text as="p" fontWeight="bold">
          3. edX:
        </Text>
        <Text as="p">
          Similar to Coursera, edX is an online learning platform that offers
          courses and certifications in tech-related subjects. It also has a
          focus on offering courses from top universities and institutions
          around the world.
        </Text>

        <Text as="p" fontWeight="bold">
          4. Khan Academy:
        </Text>
        <Text as="p">
          This non-profit organization offers free online courses and resources
          in a variety of subjects, including computer science.
        </Text>

        <Text as="p" fontWeight="bold">
          5. Udemy:
        </Text>
        <Text as="p">
          Udemy is an online platform that offers a wide range of courses in
          computer science and other subjects, including a number of programming
          courses. The courses are taught by industry experts and include video
          lectures, quizzes, and projects to help you learn.
        </Text>

        <Text as="p" fontWeight="bold">
          6. Stack Overflow:
        </Text>
        <Text as="p">
          This is a popular question and answer site for programmers. It's a
          great place to ask for help or advice on technical issues you're
          encountering.
        </Text>
      </VStack>

      <VStack marginTop="10" spacing="2" alignItems="flex-start">
        <Heading as="h2">Resume suggestions</Heading>
        <Box p={4}>
          <Text mt={4}>
            Here are some suggestions for creating a resume as a web developer:
          </Text>
          <Box mt={2}>
            <Text as="li" mb={1}>
              Include your contact information at the top of your resume,
              including your name, email address, and phone number.
            </Text>
            <Text as="li" mb={1}>
              Use a clear and easy-to-read font, such as Arial or Calibri, and
              keep your resume to one or two pages in length.
            </Text>
            <Text as="li" mb={1}>
              Start with a summary or objective statement that outlines your
              skills and experiences as a web developer.
            </Text>
            <Text as="li" mb={1}>
              In the experience section of your resume, list your past jobs and
              projects in reverse chronological order. Include the name of the
              company or organization you worked for, your job title, and a
              brief description of your responsibilities and accomplishments.
            </Text>
            <Text as="li" mb={1}>
              Use bullet points to highlight your skills and accomplishments,
              and be specific about the technologies and tools you have used.
            </Text>
            <Text as="li" mb={1}>
              Include any relevant education or training, such as a degree in
              computer science or web development, or certification in a
              specific programming language.
            </Text>
            <Text as="li" mb={1}>
              If you have any side projects or personal websites, consider
              including them in the portfolio section of your resume.
            </Text>
            <Text as="li" mb={1}>
              Consider including any relevant hobbies or extracurricular
              activities, such as involvement in a local tech meetup or
              participation in online coding challenges.
            </Text>
            <Text as="li" mb={1}>
              Proofread your resume carefully to ensure that it is free of
              errors and typos.
            </Text>
          </Box>
          <Text mt={4}>
            Overall, your resume should effectively communicate your skills and
            experiences as a web developer, and highlight the value you can
            bring to an organization.
          </Text>
        </Box>
      </VStack>

      <VStack marginTop="10" spacing="2" alignItems="flex-start">
        <Heading as="h2">Portfolio</Heading>
        <Box p={4}>
          <List>
            <ListItem>
              A portfolio demonstrates your skills and abilities.
              <Text mt={2}>
                As a web developer, your portfolio allows you to showcase your
                best work and demonstrate what you are capable of. A portfolio
                allows you to highlight your technical expertise and
                problem-solving skills, and gives potential employers or clients
                a sense of your style and approach to web development.
              </Text>
            </ListItem>
            <ListItem>
              A portfolio helps you stand out in a crowded job market.
              <Text mt={2}>
                Web development is a competitive field, and having a
                well-crafted portfolio can help you stand out from the crowd. A
                portfolio allows you to showcase your unique style and approach
                to web development, which can set you apart from other
                candidates.
              </Text>
            </ListItem>
            <ListItem>
              A portfolio helps you showcase your passion for web development.
              <Text mt={2}>
                Web development is more than just a job – it's a passion. A
                portfolio allows you to share your excitement for the field and
                show potential employers or clients that you are truly dedicated
                to your craft.
              </Text>
            </ListItem>
            <ListItem>
              A portfolio is a living document.
              <Text mt={2}>
                Your portfolio is not a static document – it should be updated
                and refined over time. As you continue to learn and grow as a
                web developer, your portfolio should reflect these changes. This
                allows you to keep your portfolio current and relevant, and
                demonstrate your ongoing commitment to the field.
              </Text>
            </ListItem>
          </List>
        </Box>
      </VStack>

      <VStack marginTop="10" spacing="2" alignItems="flex-start">
        <Heading as="h2">Practice coding</Heading>
        <Box p={4}>
          <Text>
            Practicing coding is important for several reasons when it comes to
            becoming a web developer:
          </Text>
          <List mt={4}>
            <ListItem>
              Practice helps you improve your skills and knowledge.
              <Text mt={2}>
                As with any skill, the more you practice coding, the better you
                will become. Practicing regularly allows you to try out new
                techniques, test your understanding of different concepts, and
                learn from your mistakes.
              </Text>
            </ListItem>
            <ListItem>
              Practice helps you stay current with the latest technologies.
              <Text mt={2}>
                The field of web development is constantly evolving, and it's
                important to stay up-to-date with the latest technologies and
                best practices. Practicing regularly allows you to familiarize
                yourself with new languages, frameworks, and tools, and helps
                you stay current in the field.
              </Text>
            </ListItem>
            <ListItem>
              Practice helps you build a strong foundation.
              <Text mt={2}>
                Web development involves many different technologies and
                concepts, and it's important to have a strong foundation in
                order to build complex and reliable applications. Practicing
                regularly allows you to build a solid foundation of knowledge
                and skills, which will serve you well as you advance in your
                career.
              </Text>
            </ListItem>
            <ListItem>
              Practice helps you build confidence.
              <Text mt={2}>
                Building and debugging code can be challenging, and it's natural
                to feel unsure of yourself when you're just starting out.
                However, the more you practice, the more confident you will
                become in your abilities. As you solve problems and complete
                projects, you will develop a sense of accomplishment and
                self-assurance that will serve you well in your career.
              </Text>
            </ListItem>
          </List>
          <Text>
            Overall, practicing coding is an essential part of becoming a
            successful web developer. It allows you to improve your skills, stay
            current with the latest technologies, build a strong foundation, and
            build confidence in your abilities. So if you're looking to become a
            web developer, make sure to set aside time for regular practice!
          </Text>
        </Box>
      </VStack>

      <VStack marginTop="10" spacing="2" alignItems="flex-start">
        <Heading as="h2">Books</Heading>

        <Box p={4}>
          <Text>
            Here are a few books that are highly recommended for learning how to
            become a web developer:
          </Text>
          <List mt={4}>
            <ListItem>
              <Link
                color="blue.500"
                fontWeight="bold"
                href="https://amzn.to/3vEZ3T4"
              >
                "Eloquent JavaScript" by Marijn Haverbeke:{' '}
              </Link>{' '}
              This book is a comprehensive introduction to the JavaScript
              programming language, and is suitable for both beginners and
              experienced developers. It covers a wide range of topics,
              including basic programming concepts, the DOM (Document Object
              Model), and object-oriented programming.
            </ListItem>
            <ListItem>
              <br />
              <Link
                color="blue.500"
                fontWeight="bold"
                href="https://amzn.to/3ZcBxtZ"
              >
                "HTML & CSS: Design and Build Websites" by Jon Duckett:
              </Link>{' '}
              This book is a beginner-friendly introduction to HTML and CSS, the
              two technologies used to build websites. It covers the basics of
              web design and development, and includes plenty of examples and
              exercises to help you practice your skills.
            </ListItem>
            <br />
            <ListItem>
              <Link
                color="blue.500"
                fontWeight="bold"
                href="https://amzn.to/3Qf6Y2N"
              >
                "JavaScript: The Good Parts" by Douglas Crockford:
              </Link>{' '}
              This book is a concise and in-depth guide to the core concepts of
              JavaScript, and is suitable for developers of all levels. It
              covers topics such as functions, objects, and arrays, and is
              considered a classic in the field.
            </ListItem>
            <br />
            <ListItem>
              <Link
                color="blue.500"
                fontWeight="bold"
                href="https://amzn.to/3jSAb7v"
              >
                "The Pragmatic Programmer: From Journeyman to Master" by Andrew
                Hunt and David Thomas:
              </Link>{' '}
              This book is a comprehensive guide to the principles of software
              development, and is suitable for developers of all levels. It
              covers a wide range of topics, including design patterns,
              debugging, and testing, and is considered a must-read for any
              serious programmer.
            </ListItem>
            <br />
            <ListItem>
              <Link
                color="blue.500"
                fontWeight="bold"
                href="https://amzn.to/3X4iuAf"
              >
                "Cracking the Coding Interview" by Gayle Laakmann McDowell:
              </Link>{' '}
              This book is a comprehensive guide to preparing for technical
              interviews in the field of computer science. It includes a wide
              range of practice questions and solutions, as well as advice on
              how to approach common interview questions and challenges.
            </ListItem>
            <br />
          </List>
        </Box>
      </VStack>
    </Container>
  );
};

export default ArticleList;
