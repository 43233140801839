import React, { useState, useEffect, useRef } from 'react';
import {
    Heading,
    Text
  } from '@chakra-ui/react';

const wordArray = ['achievable', 'smart', 'doable', 'obtainable', 'future-proof', 'realizable', 'brilliant', 'attainable', 'in the cards', 'realistic' ];

const WordChanger = () => {
	const [currWord, setCurrWord] = useState(wordArray[0]);
	const [isActive, setIsActive] = useState(true);

	const index = useRef(0);
	useEffect(() => {
		let interval = null;
		if (isActive) {
			interval = setInterval(() => {
				index.current++;
				setCurrWord(wordArray[index.current]);
				if (index.current === wordArray.length - 1) {
					setIsActive(false);
				}
			}, 1000);
		}
		return () => clearInterval(interval);
	});

	return (
		<div style={{textAlign: "center"}}>
            <Heading as="h1">Getting a job in the tech industry is <Text as='del'>too hard</Text> <Text color='#4299e1'>{currWord}</Text> </Heading>
		</div>
	);
};

export default WordChanger;