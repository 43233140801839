import React from 'react';
import {
  Box,
  Heading,
  Image,
  Text,
  Divider,
  HStack,
  Tag,
  Wrap,
  WrapItem,
  SpaceProps,
  useColorModeValue,
  Container,
  VStack,
  List,
  ListItem,
  Link
} from '@chakra-ui/react';

interface IBlogTags {
  tags: Array<string>;
  marginTop?: SpaceProps['marginTop'];
}

const BlogTags: React.FC<IBlogTags> = (props) => {
  return (
    <HStack spacing={2} marginTop={props.marginTop}>
      {props.tags.map((tag) => {
        return (
          <Tag size={'md'} variant="solid" colorScheme="orange" key={tag}>
            {tag}
          </Tag>
        );
      })}
    </HStack>
  );
};

interface BlogAuthorProps {
  date: Date;
  name: string;
}

export const BlogAuthor: React.FC<BlogAuthorProps> = (props) => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Image
        borderRadius="full"
        boxSize="40px"
        src="https://100k-faces.glitch.me/random-image"
        alt={`Avatar of ${props.name}`}
      />
      <Text fontWeight="medium">{props.name}</Text>
      <Text>—</Text>
      <Text>{props.date.toLocaleDateString()}</Text>
    </HStack>
  );
};

const RecommendedBooks = () => {
  return (
    <Container maxW={'7xl'} p="12">
      <VStack spacing="2" alignItems="flex-start">
        <Heading as="h2">Recommendations</Heading>
 <Text as='p'>Below is a list of my favorite books for getting started in the tech industry</Text>

<Text fontWeight='bold' color='yellow.700'>
FYI: I'm a proud affiliate for some of the resources discussed below. If you make a purchase through my links, I'll get a small commision. Thank you
</Text>
      </VStack>

      <VStack marginTop="10" spacing="2" alignItems="flex-start">
        <Heading as="h2">Books</Heading>

        <Box p={4}>
      <Text>
        Here are a few books that are highly recommended for learning how to become a web developer:
      </Text>
      <List mt={4}>
        <ListItem><Link color='blue.500' fontWeight='bold' href="https://amzn.to/3vEZ3T4">
          "Eloquent JavaScript" by Marijn Haverbeke: </Link>  This book is a comprehensive introduction to the JavaScript programming language, and is suitable for both beginners and experienced developers. It covers a wide range of topics, including basic programming concepts, the DOM (Document Object Model), and object-oriented programming.
          </ListItem>
        <ListItem>
          <br />
          <Link color='blue.500' fontWeight='bold' href="https://amzn.to/3ZcBxtZ">"HTML & CSS: Design and Build Websites" by Jon Duckett:</Link>  This book is a beginner-friendly introduction to HTML and CSS, the two technologies used to build websites. It covers the basics of web design and development, and includes plenty of examples and exercises to help you practice your skills.
        </ListItem>
        <br />
        <ListItem>
          <Link color='blue.500' fontWeight='bold' href="https://amzn.to/3Qf6Y2N">"JavaScript: The Good Parts" by Douglas Crockford:</Link>  This book is a concise and in-depth guide to the core concepts of JavaScript, and is suitable for developers of all levels. It covers topics such as functions, objects, and arrays, and is considered a classic in the field.
        </ListItem>
        <br />
        <ListItem>
        <Link color='blue.500' fontWeight='bold' href="https://amzn.to/3jSAb7v">"The Pragmatic Programmer: From Journeyman to Master" by Andrew Hunt and David Thomas:</Link>  This book is a comprehensive guide to the principles of software development, and is suitable for developers of all levels. It covers a wide range of topics, including design patterns, debugging, and testing, and is considered a must-read for any serious programmer.
        </ListItem>
        <br />
        <ListItem>
        <Link color='blue.500' fontWeight='bold' href="https://amzn.to/3X4iuAf">"Cracking the Coding Interview" by Gayle Laakmann McDowell:</Link>  This book is a comprehensive guide to preparing for technical interviews in the field of computer science. It includes a wide range of practice questions and solutions, as well as advice on how to approach common interview questions and challenges.
        </ListItem>
        <br />
      </List>
    </Box>
      </VStack>
    </Container>
  );
};

export default RecommendedBooks;