import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import {
  auth,
  registerWithEmailAndPassword,
  signInWithGoogle,
} from '../../firebase';
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  HStack,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import Link from '../Link/Link';

function Register() {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  const register = () => {
    if (!name) alert('Please enter name');
    if (!password) alert('Please enter password');
    if (!email) alert('Please enter email');
    registerWithEmailAndPassword(name, email, password);
  };
  useEffect(() => {
    if (loading) return;
    if (user) navigate('/loading-subscription');
  }, [user, loading, navigate,]);

  return (
    <Flex
      minH={'85vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={2} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'3xl'} textAlign={'center'}>
            Sign up for an account
          </Heading>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}>
          <Stack spacing={4}>
            <form
              onSubmit={e => {
                e.preventDefault();
                register();
              }}
            >
              <Stack spacing={2}>
                <FormControl>
                  <FormLabel>Full name</FormLabel>
                  <Input
                    type="text"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    autoComplete="username"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Email</FormLabel>
                  <Input
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    type="email"
                    autoComplete="email"
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Password</FormLabel>
                  <Input
                    type="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    autoComplete="current-password"
                    minLength="6"
                  />
                </FormControl>
              </Stack>
              <Button w="100%" my="2" type="submit">
                Sign up
              </Button>
            </form>
            {/* <Button bg="#4285f4" color="white" onClick={signInWithGoogle}>
              Sign up with Google
            </Button> */}
            <Box>
              Already have an account? <Link to="/login">Sign in</Link> now.
            </Box>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}

export default Register;
