import React from 'react';
import {
  Box,
  Heading,
  Image,
  Text,
  Divider,
  HStack,
  Tag,
  Wrap,
  WrapItem,
  SpaceProps,
  useColorModeValue,
  Container,
  VStack,
  List,
  ListItem,
  Link
} from '@chakra-ui/react';

interface IBlogTags {
  tags: Array<string>;
  marginTop?: SpaceProps['marginTop'];
}

const BlogTags: React.FC<IBlogTags> = (props) => {
  return (
    <HStack spacing={2} marginTop={props.marginTop}>
      {props.tags.map((tag) => {
        return (
          <Tag size={'md'} variant="solid" colorScheme="orange" key={tag}>
            {tag}
          </Tag>
        );
      })}
    </HStack>
  );
};

interface BlogAuthorProps {
  date: Date;
  name: string;
}

export const BlogAuthor: React.FC<BlogAuthorProps> = (props) => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Image
        borderRadius="full"
        boxSize="40px"
        src="https://100k-faces.glitch.me/random-image"
        alt={`Avatar of ${props.name}`}
      />
      <Text fontWeight="medium">{props.name}</Text>
      <Text>—</Text>
      <Text>{props.date.toLocaleDateString()}</Text>
    </HStack>
  );
};

const RecommendedOnlineCourses = () => {
  return (
    <Container maxW={'7xl'} p="12">
      <VStack spacing="2" alignItems="flex-start">
        <Heading as="h2">Recommendations</Heading>
 <Text as='p'>Below is a list of online courses for getting started in the tech industry</Text>

<Text fontWeight='bold' color='yellow.700'>
FYI: I'm a proud affiliate for some of the resources discussed below. If you make a purchase through my links, I'll get a small commision. Thank you
</Text>
      </VStack>

      <VStack marginTop="10" spacing="2" alignItems="flex-start">
        <Heading as="h2">Online courses</Heading>
        <Text>
  There are many online tools and resources available to help you get started in the tech industry. Here are a few that might be helpful:
</Text>

<Text as="p" fontWeight="bold">1. Codecademy:</Text>
<Text as="p">
  This is a popular platform for learning how to code. It offers interactive courses in a variety of programming languages, including Python, Java, C++, and more.
</Text>

<Text as="p" fontWeight="bold">2. Coursera:</Text>
<Text as="p">
  This online learning platform offers a wide range of courses and certifications in tech-related topics, including computer science, data science, and software engineering.
</Text>

<Text as="p" fontWeight="bold">3. edX:</Text>
<Text as="p">
  Similar to Coursera, edX is an online learning platform that offers courses and certifications in tech-related subjects. It also has a focus on offering courses from top universities and institutions around the world.
</Text>

<Text as="p" fontWeight="bold">4. Khan Academy:</Text>
<Text as="p">
  This non-profit organization offers free online courses and resources in a variety of subjects, including computer science.
</Text>

<Text as="p" fontWeight="bold">5. Udemy:</Text>
<Text as="p">
Udemy is an online platform that offers a wide range of courses in computer science and other subjects, including a number of programming courses. The courses are taught by industry experts and include video lectures, quizzes, and projects to help you learn.
</Text>

<Text as="p" fontWeight="bold">6. Stack Overflow:</Text>
<Text as="p">
  This is a popular question and answer site for programmers. It's a great place to ask for help or advice on technical issues you're encountering.
</Text>
      </VStack>
    </Container>
  );
};

export default RecommendedOnlineCourses;