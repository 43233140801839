import React from 'react';
import {
  Box,
  Heading,
  Link,
  Image,
  Text,
  Divider,
  HStack,
  Tag,
  Wrap,
  WrapItem,
  SpaceProps,
  useColorModeValue,
  Container,
  VStack,
} from '@chakra-ui/react';

interface IBlogTags {
  tags: Array<string>;
  marginTop?: SpaceProps['marginTop'];
}

const BlogTags: React.FC<IBlogTags> = (props) => {
  return (
    <HStack spacing={2} marginTop={props.marginTop}>
      {props.tags.map((tag) => {
        return (
          <Tag size={'md'} variant="solid" colorScheme="orange" key={tag}>
            {tag}
          </Tag>
        );
      })}
    </HStack>
  );
};

interface BlogAuthorProps {
  date: Date;
  name: string;
}

export const BlogAuthor: React.FC<BlogAuthorProps> = (props) => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Image
        borderRadius="full"
        boxSize="40px"
        src="https://100k-faces.glitch.me/random-image"
        alt={`Avatar of ${props.name}`}
      />
      <Text fontWeight="medium">{props.name}</Text>
      <Text>—</Text>
      <Text>{props.date.toLocaleDateString()}</Text>
    </HStack>
  );
};

const ArticleList = () => {
  return (
    <Container maxW={'7xl'} p="12">
      <VStack spacing="2" alignItems="flex-start">
        <Heading as="h2">Don't let fear of failure hold you back from getting a job in tech</Heading>
        <Box mt={8}>
  <Text mb={4}>
    It's natural to feel fear when it comes to applying for a job in the tech industry, especially if you're new to the field or don't have a lot of experience. The tech industry can be highly competitive and it's easy to feel like you're not good enough. However, letting fear hold you back can prevent you from achieving your career goals. Here are some ways to ensure that fear does not hold you back from getting a job in the tech industry:
  </Text>
  <Heading as="h2" size="lg" mb={2}>
    1. Educate yourself
  </Heading>
  <Text mb={4}>
    The more you know about the tech industry and the specific role you're applying for, the more confident you'll be in your abilities. Take time to research the company and the job requirements, and familiarize yourself with the technologies and skills that are relevant to the position.
  </Text>
  <Heading as="h2" size="lg" mb={2}>
    2. Build your skills
  </Heading>
  <Text mb={4}>
    In addition to educating yourself, it's important to actively work on improving your skills. This can include taking online courses, attending meetups and workshops, and practicing on your own. The more you practice and build your skills, the more confident you'll be when it comes time to apply for a job.
  </Text>
  <Heading as="h2" size="lg" mb={2}>
    3. Network
  </Heading>
  <Text mb={4}>
    The tech industry is all about connections, so it's important to build a strong network of professionals who can provide support and guidance. Attend industry events and make an effort to connect with people in your field. You never know who might be able to help you land your dream job.
  </Text>
  <Heading as="h2" size="lg" mb={2}>
    4. Prepare for common interview questions
  </Heading>
  <Text mb={4}>
    Many tech interviews will include questions about your technical skills and problem-solving abilities. To prepare for these questions, practice with friends or colleagues and review common interview questions. This will help you feel more confident and prepared when it comes time for the real interview.
  </Text>
  <Heading as="h2" size="lg" mb={2}>
    5. Don't be afraid to ask for help
  </Heading>
  <Text mb={4}>
    If you're feeling overwhelmed or uncertain about your job search, don't be afraid to reach out to friends, family, or a career coach for support. They can provide valuable guidance and encouragement to help you overcome your fears and achieve your career goals.
  </Text>
  <Heading as="h2" size="lg" mb={2}>
    6. Remember that everyone has to start somewhere
  </Heading>
  <Text mb={4}>
    It's easy to feel like everyone in the tech industry has years of experience and advanced degrees, but the truth is that everyone has to start somewhere. Don't let your lack of experience hold you back. Focus on the skills and knowledge you do have, and be willing to learn and grow as you gain more experience.
  </Text>
  <Heading as='h2' size='lg' mb={2}>
  7. Practice self-care
  </Heading>
  <Text mb={4}>
     Job searching can be stressful, so it's important to take care of yourself during this process. Make sure to get enough sleep, exercise, and eat well. Taking care of yourself will help you feel more confident and better equipped to handle any challenges that come your way.
  </Text>

  <Text>
  It's natural to feel fear when applying for a job in the tech industry, but it's important not to let that fear hold you back. By educating yourself, building your skills, networking, preparing for common interview questions, seeking help when needed, and practicing self-care, you can overcome your fears and achieve your career goals.
  </Text>
  </Box>
      </VStack>
    </Container>
  );
};

export default ArticleList;