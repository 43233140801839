import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  auth,
  logInWithEmailAndPassword,
  signInWithGoogle,
} from '../../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  useColorModeValue,
} from '@chakra-ui/react';

import Link from '../Link/Link';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [user, loading] = useAuthState(auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) navigate('/account');
  }, [user, loading, navigate]);
  return (
    <Flex
      minH={'75vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}>
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={2} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'3xl'}>Sign in to your account</Heading>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}>
          <form
            onSubmit={e => {
              e.preventDefault();
              logInWithEmailAndPassword(email, password);
            }}
          >
            <FormControl>
              <FormLabel htmlFor="email">Email Address</FormLabel>
              <Input
                value={email}
                onChange={e => setEmail(e.target.value)}
                type="email"
                autoComplete="email"
              />
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="password">Password</FormLabel>
              <Input
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                autoComplete="current-password"
              />
            </FormControl>
            <Button w="100%" my="2" type="submit">
              Login
            </Button>
            {/* <Button bg="#4285f4" w="100%" my="2" color="white" onClick={signInWithGoogle}>
              Login with Google
            </Button> */}
          </form>
          <Link to="/reset-password">Forgot Password</Link>
          <Box>
            Don't have an account? <Link to="/signup">Register</Link> now.
          </Box>
        </Box>
      </Stack>
    </Flex>
  );
}

export default Login;
