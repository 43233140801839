import React from 'react';
import {
  Box,
  Heading,
  Link,
  Image,
  Text,
  Divider,
  HStack,
  Tag,
  Wrap,
  WrapItem,
  SpaceProps,
  useColorModeValue,
  Container,
  VStack,
} from '@chakra-ui/react';

interface IBlogTags {
  tags: Array<string>;
  marginTop?: SpaceProps['marginTop'];
}

const BlogTags: React.FC<IBlogTags> = props => {
  return (
    <HStack spacing={2} marginTop={props.marginTop}>
      {props.tags.map(tag => {
        return (
          <Tag size={'md'} variant="solid" colorScheme="orange" key={tag}>
            {tag}
          </Tag>
        );
      })}
    </HStack>
  );
};

interface BlogAuthorProps {
  date: Date;
  name: string;
}

export const BlogAuthor: React.FC<BlogAuthorProps> = props => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Image
        borderRadius="full"
        boxSize="40px"
        src="https://100k-faces.glitch.me/random-image"
        alt={`Avatar of ${props.name}`}
      />
      <Text fontWeight="medium">{props.name}</Text>
      <Text>—</Text>
      <Text>{props.date.toLocaleDateString()}</Text>
    </HStack>
  );
};

const ArticleList = () => {
  return (
    <Container maxW={'7xl'} p="12">
      <VStack spacing="2" alignItems="flex-start">
        <Heading as="h2">Building a portfolio that stands out</Heading>
        <Box mt={8}>
          <Text mb={4}>
            As a developer, having a portfolio that stands out can be crucial
            for your career success. A portfolio is a collection of your work
            that showcases your skills and experience to potential employers and
            clients. It's an opportunity to demonstrate your capabilities and
            set yourself apart from other candidates. Here are some reasons why
            you should build a portfolio that stands out:
          </Text>

          <Heading as="h2" size="lg" mb={2}>
            1. It showcases your skills and experience
          </Heading>
          <Text mb={4}>
          A strong portfolio allows you to demonstrate the full range of your skills and experience to potential employers and clients. It gives you a chance to show off your best work and highlight your unique capabilities. This is especially important if you're just starting out in your career or have limited experience. A portfolio can help you stand out and show potential employers that you have the skills and experience they're looking for.
          </Text>
          <Heading as="h2" size="lg" mb={2}>
            2. It helps you stand out in a crowded job market
          </Heading>
          <Text mb={4}>
          The tech industry is highly competitive, and there are often many qualified candidates applying for the same job. A standout portfolio can help you stand out from the crowd and differentiate yourself from other candidates. It's an opportunity to showcase your creativity and problem-solving skills and show potential employers that you're the best fit for the role.
          </Text>
          <Heading as="h2" size="lg" mb={2}>
            3. It helps you build your brand
          </Heading>
          <Text mb={4}>
          Your portfolio is a reflection of your personal brand and style. It's an opportunity to showcase your personality and show potential employers and clients what makes you unique. A strong portfolio can help you build your brand and establish yourself as a thought leader in your field.
          </Text>
          <Heading as="h2" size="lg" mb={2}>
            4. It can lead to new opportunities
          </Heading>
          <Text mb={4}>
          A standout portfolio can open up new opportunities and help you land your dream job or client. It's a chance to showcase your work to a wider audience and potentially attract new business.
          </Text>
          <Heading as="h2" size="lg" mb={2}>
            5. It's a living document
          </Heading>
          <Text mb={4}>
          Your portfolio should be a living document that evolves as you gain new skills and experience. As you work on new projects and add to your portfolio, it will continue to grow and reflect your current abilities. This is important because it shows potential employers and clients that you're always learning and growing as a developer.
          </Text>

          <Text>
          Building a portfolio that stands out is essential for any aspiring developer. It's a chance to showcase your skills and experience, stand out in a crowded job market, build your brand, and open up new opportunities. As you continue to grow and evolve as a developer, be sure to keep your portfolio up-to-date and reflective of your current abilities.
          </Text>
        </Box>
      </VStack>
    </Container>
  );
};

export default ArticleList;
