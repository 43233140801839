import React from 'react';
import {
  Box,
  Heading,
  Image,
  Text,
  Stack,
  Divider,
  Link,
  HStack,
  Tag,
  Wrap,
  WrapItem,
  SpaceProps,
  useColorModeValue,
  Container,
  VStack,
  List,
  ListItem,
} from '@chakra-ui/react';
import GetStartedSoftwareEng from '../components/GetStartedSoftwareEng';

interface IBlogTags {
  tags: Array<string>;
  marginTop?: SpaceProps['marginTop'];
}

const BlogTags: React.FC<IBlogTags> = props => {
  return (
    <HStack spacing={2} marginTop={props.marginTop}>
      {props.tags.map(tag => {
        return (
          <Tag size={'md'} variant="solid" colorScheme="orange" key={tag}>
            {tag}
          </Tag>
        );
      })}
    </HStack>
  );
};

interface BlogAuthorProps {
  date: Date;
  name: string;
}

export const BlogAuthor: React.FC<BlogAuthorProps> = props => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Image
        borderRadius="full"
        boxSize="40px"
        src="https://100k-faces.glitch.me/random-image"
        alt={`Avatar of ${props.name}`}
      />
      <Text fontWeight="medium">{props.name}</Text>
      <Text>—</Text>
      <Text>{props.date.toLocaleDateString()}</Text>
    </HStack>
  );
};

const ArticleList = () => {
  return (
    <Container maxW={'7xl'} p="12">
      <GetStartedSoftwareEng />

      <br />

      <Text fontWeight="bold" color="yellow.700">
        FYI: I'm a proud affiliate for some of the resources discussed below. If
        you make a purchase through my links, I'll get a small commision. Thank
        you
      </Text>

      <VStack marginTop="10" spacing="2" alignItems="flex-start">
        <Heading as="h2">Online courses</Heading>
        <Text>
          There are many online tools and resources available to help you get
          started in the tech industry. Here are a few that might be helpful:
        </Text>

        <Text as="p" fontWeight="bold">
          1. Codecademy:
        </Text>
        <Text as="p">
          This is a popular platform for learning how to code. It offers
          interactive courses in a variety of programming languages, including
          Python, Java, C++, and more.
        </Text>

        <Text as="p" fontWeight="bold">
          2. Coursera:
        </Text>
        <Text as="p">
          This online learning platform offers a wide range of courses and
          certifications in tech-related topics, including computer science,
          data science, and software engineering.
        </Text>

        <Text as="p" fontWeight="bold">
          3. edX:
        </Text>
        <Text as="p">
          Similar to Coursera, edX is an online learning platform that offers
          courses and certifications in tech-related subjects. It also has a
          focus on offering courses from top universities and institutions
          around the world.
        </Text>

        <Text as="p" fontWeight="bold">
          4. Khan Academy:
        </Text>
        <Text as="p">
          This non-profit organization offers free online courses and resources
          in a variety of subjects, including computer science.
        </Text>

        <Text as="p" fontWeight="bold">
          5. Udemy:
        </Text>
        <Text as="p">
          Udemy is an online platform that offers a wide range of courses in
          computer science and other subjects, including a number of programming
          courses. The courses are taught by industry experts and include video
          lectures, quizzes, and projects to help you learn.
        </Text>

        <Text as="p" fontWeight="bold">
          6. Stack Overflow:
        </Text>
        <Text as="p">
          This is a popular question and answer site for programmers. It's a
          great place to ask for help or advice on technical issues you're
          encountering.
        </Text>
      </VStack>

      <VStack marginTop="10" spacing="2" alignItems="flex-start">
        <Heading as="h2">Resume suggestions</Heading>
        <Box p={4}>
          <Text mt={4}>
            Here are some suggestions for creating a resume as a software
            engineer:
          </Text>
          <Box mt={2}>
            <Text as="li" mb={1}>
              Include your contact information at the top of your resume,
              including your name, email address, and phone number.
            </Text>
            <Text as="li" mb={1}>
              Use a clear and easy-to-read font, such as Arial or Calibri, and
              keep your resume to one or two pages in length.
            </Text>
            <Text as="li" mb={1}>
              Start with a summary or objective statement that outlines your
              skills and experiences as a software engineer.
            </Text>
            <Text as="li" mb={1}>
              In the experience section of your resume, list your past jobs and
              projects in reverse chronological order. Include the name of the
              company or organization you worked for, your job title, and a
              brief description of your responsibilities and accomplishments.
            </Text>
            <Text as="li" mb={1}>
              Use bullet points to highlight your skills and accomplishments,
              and be specific about the technologies and tools you have used.
            </Text>
            <Text as="li" mb={1}>
              Include any relevant education or training, such as a degree in
              computer science or software engineering, or certification in a
              specific programming language.
            </Text>
            <Text as="li" mb={1}>
              If you have any side projects or personal websites, consider
              including them in the portfolio section of your resume.
            </Text>
            <Text as="li" mb={1}>
              Consider including any relevant hobbies or extracurricular
              activities, such as involvement in a local tech meetup or
              participation in online coding challenges.
            </Text>
            <Text as="li" mb={1}>
              Proofread your resume carefully to ensure that it is free of
              errors and typos.
            </Text>
          </Box>
          <Text mt={4}>
            Overall, your resume should effectively communicate your skills and
            experiences as a software engineer, and highlight the value you can
            bring to an organization.
          </Text>
        </Box>
      </VStack>

      <VStack marginTop="10" spacing="2" alignItems="flex-start">
        <Heading as="h2">Portfolio</Heading>
        <Box p={4}>
          <List>
            <ListItem>
              A portfolio demonstrates your skills and capabilities.
              <Text mt={2}>
                A portfolio is a collection of projects that you have completed,
                and it demonstrates your skills and capabilities as a software
                engineer. By showcasing your work, you can demonstrate to
                potential employers or clients what you are capable of and how
                you approach problem-solving.
              </Text>
            </ListItem>
            <ListItem>
              A portfolio sets you apart from other candidates.
              <Text mt={2}>
                In a competitive job market, having a portfolio can help set you
                apart from other candidates. By showing your work and the
                projects you have completed, you can demonstrate your passion
                and dedication to software engineering, and showcase your unique
                skills and abilities.
              </Text>
            </ListItem>
            <ListItem>
              A portfolio allows you to showcase your best work.
              <Text mt={2}>
                A portfolio allows you to showcase your best work and the
                projects that you are most proud of. By selecting your best
                projects and presenting them in a professional way, you can
                demonstrate your skills and accomplishments to potential
                employers or clients.
              </Text>
            </ListItem>
            <ListItem>
              A portfolio can help you get feedback and improve your skills.
              <Text mt={2}>
                Sharing your portfolio with others can help you get feedback on
                your work and identify areas where you can improve your skills.
                By soliciting feedback and using it to improve your work, you
                can become a stronger and more effective software engineer.
              </Text>
            </ListItem>
          </List>
        </Box>
      </VStack>

      <VStack marginTop="10" spacing="2" alignItems="flex-start">
        <Heading as="h2">Practice coding</Heading>
        <Box p={4}>
          <List>
            <ListItem>
              Practice helps you develop your skills.
              <Text mt={2}>
                Coding is a skill, and like any skill, it takes practice to
                develop and improve. By practicing coding regularly, you can
                improve your skills and become a more proficient and effective
                software engineer.
              </Text>
            </ListItem>
            <ListItem>
              Practice helps you learn new technologies and languages.
              <Text mt={2}>
                The field of software engineering is constantly evolving, and
                there are always new technologies and programming languages
                being developed. By practicing coding, you can learn these new
                technologies and languages and stay current in the field.
              </Text>
            </ListItem>
            <ListItem>
              Practice helps you build confidence.
              <Text mt={2}>
                Coding can be challenging, and it can be easy to feel
                overwhelmed or uncertain when you are first learning. By
                practicing regularly, you can build your confidence and become
                more comfortable and confident with coding.
              </Text>
            </ListItem>
            <ListItem>
              Practice helps you build a portfolio.
              <Text mt={2}>
                A strong portfolio of coding projects is an important part of
                being a software engineer, and practicing coding can help you
                build up your portfolio. By completing coding projects on your
                own and showcasing your skills, you can demonstrate your
                capabilities to potential employers or clients.
              </Text>
            </ListItem>
          </List>
        </Box>
      </VStack>

      <VStack marginTop="10" spacing="2" alignItems="flex-start">
        <Heading as="h2">Books</Heading>
        <Box p={4}>
          <List>
            <ListItem>
              <Link
                color="blue.500"
                fontWeight="bold"
                href="https://amzn.to/3CLIvNl"
              >
                "Code Complete" by Steve McConnell:
              </Link>{' '}
              This book is a comprehensive guide to software construction, and
              covers a wide range of topics including design patterns,
              debugging, and testing. It is suitable for developers of all
              levels and is considered a classic in the field.
            </ListItem>
            <br />
            <ListItem>
              <Link
                color="blue.500"
                fontWeight="bold"
                href="https://amzn.to/3QmJ3Pe"
              >
                "Design Patterns: Elements of Reusable Object-Oriented Software"
                by Erich Gamma, Richard Helm, Ralph Johnson, and John Vlissides:
              </Link>{' '}
              This book is a comprehensive guide to design patterns, and is
              considered a classic in the field. It covers a wide range of
              patterns and is suitable for developers of all levels.
            </ListItem>
            <br />
            <ListItem>
              <Link
                color="blue.500"
                fontWeight="bold"
                href="https://amzn.to/3jRWXww"
              >
                "Clean Code: A Handbook of Agile Software Craftsmanship" by
                Robert C. Martin:
              </Link>{' '}
              This book is a guide to writing clean, maintainable, and efficient
              code, and covers a wide range of topics including design patterns,
              testing, and refactoring. It is suitable for developers of all
              levels and is considered a must-read in the field.
            </ListItem>
            <br />
            <ListItem>
              <Link
                color="blue.500"
                fontWeight="bold"
                href="https://amzn.to/3jSAb7v"
              >
                "The Pragmatic Programmer: From Journeyman to Master" by Andrew
                Hunt and David Thomas:
              </Link>{' '}
              This book is a comprehensive guide to the principles of software
              development, and is suitable for developers of all levels. It
              covers a wide range of topics, including design patterns,
              debugging, and testing, and is considered a must-read for any
              serious programmer.
            </ListItem>
            <br />
            <ListItem>
              <Link
                color="blue.500"
                fontWeight="bold"
                href="https://amzn.to/3X4iuAf"
              >
                "Cracking the Coding Interview" by Gayle Laakmann McDowell:
              </Link>{' '}
              This book is a comprehensive guide to preparing for technical
              interviews in the field of computer science. It includes a wide
              range of practice questions and solutions, as well as advice on
              how to approach common interview questions and challenges.
            </ListItem>
          </List>
        </Box>
      </VStack>
    </Container>
  );
};

export default ArticleList;
