import React from 'react';
import {
  Box,
  Link,
  Divider,
  HStack,
  Tag,
  Wrap,
  WrapItem,
  SpaceProps,
  useColorModeValue,
  VStack,
  Button,
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  Icon,
} from '@chakra-ui/react';
import { ArrowRightIcon } from '@chakra-ui/icons'
import WordChanger from '../components/WordChanger';

interface IBlogTags {
  tags: Array<string>;
  marginTop?: SpaceProps['marginTop'];
}

const Feature = ({ text, icon, iconBg }: FeatureProps) => {
  return (
    <Stack direction={'row'} align={'center'}>
      <Flex
        w={8}
        h={8}
        align={'center'}
        justify={'center'}
        rounded={'full'}
        bg={iconBg}>
        {icon}
      </Flex>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  );
};

const BlogTags: React.FC<IBlogTags> = (props) => {
  return (
    <HStack spacing={2} marginTop={props.marginTop}>
      {props.tags.map((tag) => {
        return (
          <Tag size={'md'} variant="solid" colorScheme="orange" key={tag}>
            {tag}
          </Tag>
        );
      })}
    </HStack>
  );
};

interface BlogAuthorProps {
  date: Date;
  name: string;
}

export const BlogAuthor: React.FC<BlogAuthorProps> = (props) => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Image
        borderRadius="full"
        boxSize="40px"
        src="https://100k-faces.glitch.me/random-image"
        alt={`Avatar of ${props.name}`}
      />
      <Text fontWeight="medium">{props.name}</Text>
      <Text>—</Text>
      <Text>{props.date.toLocaleDateString()}</Text>
    </HStack>
  );
};

function LinkButton({ href, children, ...props }) {
  return (
    <Link href={href} passHref>
      <Button as="a" {...props}>
        {children}
      </Button>
    </Link>
  );
}

const ArticleList = () => {
  return (
    <Container maxW={'7xl'} p="12">
      <WordChanger />
      <Box
        marginTop={{ base: '1', sm: '5' }}
        display="flex"
        flexDirection={{ base: 'column', sm: 'row' }}
        justifyContent="space-between">
        <Box
          display="flex"
          flex="1"
          marginRight="3"
          position="relative"
          alignItems="center">
          <Box
            width={{ base: '100%', sm: '85%' }}
            zIndex="2"
            marginLeft={{ base: '0', sm: '5%' }}
            marginTop="5%">
            <Link textDecoration="none" _hover={{ textDecoration: 'none' }}>
              <Image
                borderRadius="lg"
                src={
                  'https://images.unsplash.com/photo-1517694712202-14dd9538aa97?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80'
                }
                alt="some good alt text"
                objectFit="contain"
              />
            </Link>
          </Box>
          <Box zIndex="1" width="100%" position="absolute" height="100%">
            <Box
              bgGradient={useColorModeValue(
                'radial(orange.600 1px, transparent 1px)',
                'radial(orange.300 1px, transparent 1px)'
              )}
              backgroundSize="20px 20px"
              opacity="0.4"
              height="100%"
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flex="1"
          flexDirection="column"
          justifyContent="center"
          marginTop={{ base: '3', sm: '0' }}>
          {/* <BlogTags tags={['Engineering', 'Consulting', 'Data',]} /> */}
          <Heading marginTop="1">
            <Link textDecoration="none" _hover={{ textDecoration: 'none' }}>
              Making the switch
            </Link>
          </Heading>
          <Text
            as="p"
            marginTop="2"
            color={useColorModeValue('gray.700', 'gray.200')}
            fontSize="lg">
            There are millions of jobs in the tech industry today and that number is 
            only going to increase as our world continues to rely on technology. If you've ever considered
            making a switch in your career to something more technical, let this site be your guide and motivation. <br></br><br></br>
            You absolutely CAN make a change to tech without having a degree or previous experience. I was told it
            was impossible. Speaking from experience, it's not. With the right resources and motivation, anyone can make this happen.
          </Text>

          <Stack
          marginTop='30px'
            spacing={4}
            divider={
              <StackDivider
                borderColor={useColorModeValue('gray.100', 'gray.700')}
              />
            }>
            <a href='/web-development'><Feature
              icon={
                <ArrowRightIcon color={'yellow.500'} w={5} h={5} />
              }
              iconBg={useColorModeValue('white.100', 'white.900')}
              text={'Get started in web development'}
            /></a>
            <a href='/software-engineering'><Feature
              icon={<ArrowRightIcon color={'yellow.500'} w={5} h={5} />}
              iconBg={useColorModeValue('white.100', 'white.900')}
              text={'Get started in software engineering'}
            /></a>

            <a href='/get-started-in-tech'><Feature
              icon={
                <ArrowRightIcon color={'yellow.500'} w={5} h={5} />
              }
              iconBg={useColorModeValue('white.100', 'white.900')}
              text={'Not sure? Get started in tech'}
            /></a>

<a href='/recommendations'><Feature
              icon={
                <ArrowRightIcon color={'yellow.500'} w={5} h={5} />
              }
              iconBg={useColorModeValue('white.100', 'white.900')}
              text={'General recommendations'}
            /></a>
          </Stack>


          {/* <BlogAuthor name="John Doe" date={new Date('2021-04-06T19:01:27Z')} /> */}
        </Box>
      </Box>
      <Divider marginTop="5" />
      <VStack paddingTop="40px" spacing="2" alignItems="flex-start">
        <Heading as="h2">Best advice from experience</Heading>
        <Text as="p" fontSize="lg">
          I made the switch from account management to software engineering and it was the best thing I ever did for my career. With a degree in general business and no prior coding
          experience whatsoever, I was able to make this happen and i'm here to tell you that so can you. Regardless of where you are at in your career, you can do it.
        </Text>
        <Text as="p" fontSize="lg">
          Looking back, I wish I had someone to point me in the right direction that had done what I had done - who could see
          through a similar lens - who could confidently tell me that YES it is entirely possible to shift your career in a more
          technical direction and become a software engineer from experience. 
        </Text>
        <Text as="p" fontSize="lg">
          I never had that type of guidance but I hope that this site can do that for you. There is an abundance of resources
          out there to the point that it can be overwhelming. My goal is to bring in the best resources organized in such a way that
          anyone with any background can get the tech job they really want.
        </Text>

        <LinkButton href="/blog" colorScheme="blue">
        Read more
      </LinkButton>
      </VStack>
    </Container>
  );
};

export default ArticleList;