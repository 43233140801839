import React from 'react';
import {
  Box,
  Heading,
  Link,
  Image,
  Text,
  Divider,
  HStack,
  Tag,
  Wrap,
  WrapItem,
  SpaceProps,
  useColorModeValue,
  Container,
  VStack,
} from '@chakra-ui/react';

interface IBlogTags {
  tags: Array<string>;
  marginTop?: SpaceProps['marginTop'];
}

const BlogTags: React.FC<IBlogTags> = (props) => {
  return (
    <HStack spacing={2} marginTop={props.marginTop}>
      {props.tags.map((tag) => {
        return (
          <Tag size={'md'} variant="solid" colorScheme="orange" key={tag}>
            {tag}
          </Tag>
        );
      })}
    </HStack>
  );
};

interface BlogAuthorProps {
  date: Date;
  name: string;
}

export const BlogAuthor: React.FC<BlogAuthorProps> = (props) => {
  return (
    <HStack marginTop="2" spacing="2" display="flex" alignItems="center">
      <Image
        borderRadius="full"
        boxSize="40px"
        src="https://100k-faces.glitch.me/random-image"
        alt={`Avatar of ${props.name}`}
      />
      <Text fontWeight="medium">{props.name}</Text>
      <Text>—</Text>
      <Text>{props.date.toLocaleDateString()}</Text>
    </HStack>
  );
};

const ArticleList = () => {
  return (
    <Container maxW={'7xl'} p="12">
      <VStack spacing="2" alignItems="flex-start">
        <Heading as="h2">How to become a web developer with little to no experience in tech</Heading>
        <Box mt={8}>
          <Text mb={4}>
          Are you interested in becoming a web developer but feel like you have little to no experience in tech? Don't let that hold you back! With dedication and hard work, anyone can become a web developer, even with little to no experience in tech. Here are some tips on how to get started:
          </Text>

          <Heading as="h2" size="lg" mb={2}>
            1. Educate yourself
          </Heading>
          <Text mb={4}>
          One of the most important things you can do to start your journey as a web developer is to educate yourself. There are plenty of resources available online, such as articles, tutorials, and online courses, that can help you learn about web development and the technologies involved. Take advantage of these resources to gain a solid understanding of the field and build a strong foundation of knowledge.
          </Text>
          <Heading as="h2" size="lg" mb={2}>
            2. Build your skills
          </Heading>
          <Text mb={4}>
          In addition to educating yourself, it's important to actively work on improving your skills. This can include taking online courses, attending meetups and workshops, and practicing on your own. The more you practice and build your skills, the more confident you'll be as you begin your career as a web developer.
          </Text>
          <Heading as="h2" size="lg" mb={2}>
            3. Network
          </Heading>
          <Text mb={4}>
          The tech industry is all about connections, so it's important to start building your network early on. Attend industry events and make an effort to connect with other web developers and professionals in the field. Not only can networking help you learn more about web development, but it can also help you learn about job opportunities and get valuable advice and support.
          </Text>
          <Heading as="h2" size="lg" mb={2}>
            4. Find an internship or entry-level position
          </Heading>
          <Text mb={4}>
          While it can be challenging to land a job as a web developer with little to no experience, it's not impossible. Consider looking for an internship or entry-level position to gain some real-world experience and build your resume. Even if the position is unpaid or part-time, the experience and skills you'll gain will be invaluable as you start your career.
          </Text>
          <Heading as="h2" size="lg" mb={2}>
            5. Don't be afraid to ask for help
          </Heading>
          <Text mb={4}>
          Building a career as a web developer can be intimidating, especially if you have little to no experience in tech. Don't be afraid to reach out to friends, family, or a mentor for support and guidance. They can provide valuable advice and encouragement as you start your journey as a web developer.
          </Text>

          <Text>
          In conclusion, becoming a web developer with little to no experience in tech may seem daunting, but it is possible with dedication and hard work. By educating yourself, building your skills, networking, and seeking out opportunities to gain experience, you can start your career as a web developer and achieve your goals.
          </Text>
        </Box>
        </VStack>
    </Container>
  );
};

export default ArticleList;